.inputSection {
  display: flex;
  justify-content: space-around;
  padding: 1vw;
  gap: 2vw;
  border: 1px solid grey;
}


.createNewExamSection {
  margin: 2vw;
}
.addButton {
  display: flex;
  justify-content: center;
  background: green;
  color: white;
  cursor: pointer;
}