.teacherhome-options-container .blue-button {
  width: 200px;
}
.teacherhome-options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.teacher-home_back-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.teacher-home_secondary-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
  width: auto;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.7);
}

/* live syllabus popup */

.live-syllabus-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.live-syllabus-content {
  position: relative;
  width: 90%;
  max-width: 800px;
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: 90vh;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #333;
  font-size: 1.5rem;
  cursor: pointer;
}

.close-button:hover {
  color: red;
}

.live-syllabus-button {
  position: relative;
  overflow: hidden;
}

.star-icon {
  position: absolute;
  color: yellow;
  font-size: 0.6rem; /* Reduced font size for smaller stars */
  animation: twinkle 2s infinite;
  opacity: 0; /* Initially hidden */
}

/* Adjust positions and animation delay for each star */
.live-syllabus-button .star-icon:nth-child(1) {
  top: 10%;
  left: 10%;
  animation-delay: 0.2s;
}

.live-syllabus-button .star-icon:nth-child(2) {
  top: 50%;
  left: 80%;
  animation-delay: 0.8s;
}

.live-syllabus-button .star-icon:nth-child(3) {
  top: 80%;
  left: 25%;
  animation-delay: 0.4s;
}

.live-syllabus-button .star-icon:nth-child(4) {
  top: 30%;
  left: 50%;
  animation-delay: 0.6s;
}

.live-syllabus-button .star-icon:nth-child(5) {
  top: 70%;
  left: 10%;
  animation-delay: 1s;
}

.live-syllabus-button .star-icon:nth-child(6) {
  top: 20%;
  left: 70%;
  animation-delay: 1.2s;
}
.live-syllabus-button .star-icon:nth-child(7) {
  top: 90%;
  left: 90%;
  animation-delay: 1.4s;
}

.live-syllabus-button .star-icon:nth-child(8) {
  top: 40%;
  left: 20%;
  animation-delay: 1.6s;
}

.live-syllabus-button .star-icon:nth-child(9) {
  top: 60%;
  left: 40%;
  animation-delay: 1.8s;
}

.live-syllabus-button .star-icon:nth-child(10) {
  top: 75%;
  left: 60%;
  animation-delay: 0.5s;
}

@keyframes twinkle {
  0%,
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.1); /* Slightly reduced scale */
  }
}
