.teacher-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    height: 10vh;
    background-color: rgb(6, 96, 85);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.classNameSelector {
    display: flex;
    padding: 10px;
}