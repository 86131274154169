.blue-button {
  display: flex;
  width: fit-content;
  justify-content: center;
  padding: 13px;
  background-color: rgb(0, 98, 255);
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 10px;
}

.basic-options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.orange-button {
  display: flex;
  width: fit-content;
  justify-content: center;
  padding: 13px;
  background-color: rgb(255, 98, 0);
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 10px;
}

.green-button {
  display: flex;
  width: fit-content;
  justify-content: center;
  padding: 13px;
  background-color: rgb(0, 255, 98);
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 10px;
}

.add-button {
  display: flex;
  justify-content: center;
  background: green;
  color: white;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
}

.flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.table-component {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  height: 420px;
}
