.thankyou-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  color: #343a40;
  font-family: Arial, sans-serif;
}

.thankyou-page h1 {
  font-size: 2.5em;
}

.thankyou-page p {
  font-size: 1.2em;
}