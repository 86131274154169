.entrypage-container {
    display: flex;
    flex-direction: column;
}

.entrypage-content {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin: 40px;
  gap: 20px;
}
.entrypage-content {
    display: flex;
    justify-content: space-between;
    margin: 40px;
}
@media (min-width: 768px) {
  .entrypage-content {
    flex-direction: row;
  }
}

/* .entrypage-register {
  width: 700px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.entrypage-login {
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
} */
.entrypage-register, .entrypage-login {
  /* width: 100%; */
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

@media (min-width: 768px) {
  .entrypage-register {
    width: 700px;
  }
  .entrypage-login {
    width: 40%;
  }
}

.entrypage-banner {
  display: inline-flex;
  /* justify-content: center; */
  border: 1px solid black;
  margin: 20px 40px;
  

}